import React, { useEffect, useState } from 'react';
import styles from './showcase.module.css';
import { fetchShowcaseImages } from '../../../services/sanity/sanityServices';
import { GalleryImage } from '../../../interfaces/showcaseInterfaces';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Showcase: React.FC = () => {
    const [images, setImages] = useState<GalleryImage[]>([]);
    const [loading, setLoading] = useState(true);
    const [fadingOut, setFadingOut] = useState(false);
    const [showCarousel, setShowCarousel] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        let intervalId: number | undefined;
        if (!showCarousel) {
            intervalId = window.setInterval(() => {
                void getRandomImages();
            }, 15000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [showCarousel]);

    const getRandomImages = async (): Promise<void> => {
        try {
            setFadingOut(true);
            await new Promise((resolve) => setTimeout(resolve, 1500));
            const allImages = await fetchShowcaseImages();
            const randomImages = allImages
                .sort(() => 0.5 - Math.random())
                .slice(0, 9);
            setImages(randomImages);
            setFadingOut(false);
            setLoading(false);
        } catch (error: unknown) {
            console.error('Error fetching images:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        void getRandomImages();
    }, []);

    const handleImageClick = (index: number): void => {
        setCurrentIndex(index);
        setShowCarousel(true);
    };

    const closeCarousel = (): void => {
        setShowCarousel(false);
    };

    return (
        <div id="showcase" className={styles.showcaseContainer}>
            <h1 className={styles.title}>Nos Réalisations</h1>
            <button
                className={styles.reloadButton}
                onClick={() => {
                    void getRandomImages();
                }}
            >
                Charger plus d'images
            </button>
            <div className={styles.grid}>
                {loading ? (
                    <div>Chargement des images</div>
                ) : (
                    images.map((image, index) => (
                        <img
                            key={index}
                            src={image.url}
                            alt={`Image ${String(index + 1)} de la galerie`}
                            className={`${styles.image} ${fadingOut ? styles.fadeOut : styles.fadeIn}`}
                            onClick={() => {
                                handleImageClick(index);
                            }}
                        />
                    ))
                )}
            </div>

            {showCarousel && (
                <div className={styles.carouselContainer}>
                    <button
                        className={styles.closeButton}
                        onClick={closeCarousel}
                    >
                        Fermer
                    </button>
                    <Carousel
                        selectedItem={currentIndex}
                        onChange={(index) => {
                            setCurrentIndex(index);
                        }}
                        showThumbs={false}
                        infiniteLoop={false}
                        useKeyboardArrows
                        showStatus={false}
                        swipeable
                        emulateTouch
                        autoPlay={false}
                        centerMode={true}
                        centerSlidePercentage={100}
                        renderArrowPrev={(clickHandler, hasPrev) =>
                            hasPrev && (
                                <button
                                    type="button"
                                    onClick={clickHandler}
                                    className={`${styles.arrow} ${styles.prevArrow}`}
                                    aria-label="Previous"
                                >
                                    <i className="fa fa-chevron-left"></i>
                                </button>
                            )
                        }
                        renderArrowNext={(clickHandler, hasNext) =>
                            hasNext && (
                                <button
                                    type="button"
                                    onClick={clickHandler}
                                    className={`${styles.arrow} ${styles.nextArrow}`}
                                    aria-label="Next"
                                >
                                    <i className="fa fa-chevron-right"></i>
                                </button>
                            )
                        }
                    >
                        {images.map((image, index) => (
                            <div
                                key={index}
                                className={styles.carouselImageContainer}
                            >
                                <img
                                    src={image.url}
                                    alt={`Image ${String(index + 1)} de la galerie`}
                                    className={styles.carouselImage}
                                />
                            </div>
                        ))}
                    </Carousel>
                </div>
            )}
        </div>
    );
};

const ShowcaseComponent = React.memo(Showcase);
export default ShowcaseComponent;
