import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

async function initializeI18n() {
    await i18n
        .use(HttpBackend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            lng: 'fr',
            fallbackLng: 'fr',
            load: 'languageOnly',
            debug: true,
            backend: {
                loadPath: '/locales/{{lng}}/translation.json',
            },
            interpolation: {
                escapeValue: false,
            },
            detection: {
                order: [
                    'querystring',
                    'cookie',
                    'localStorage',
                    'navigator',
                    'htmlTag',
                ],
                caches: ['localStorage', 'cookie'],
            },
        });
}

initializeI18n().catch((error: unknown) => {
    console.error('Failed to initialize i18n', error);
});

export default i18n;
