import React, { useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './home.module.css';
import { useScrollFadeEffect } from '../../shared/hooks/parallax/useScrollFadeEffect';
import { useScrollParallax } from '../../shared/hooks/parallax/useScrollParallax';
import LogoImage from '../../../../images/logotignasse.png';
import { fetchHomeImage } from '../../../services/sanity/sanityServices';
import { useImage } from '../../shared/hooks/useImage/useImage';

const Home: React.FC = () => {
    const homeRef = useRef<HTMLElement>(null);
    const opacity = useScrollFadeEffect(1800, homeRef);
    const firstNameOffset = useScrollParallax(0.35, -1);
    const lastNameOffset = useScrollParallax(0.3, 1);
    const homeImage = useImage(fetchHomeImage);

    return (
        <section
            ref={homeRef}
            className={styles.homeContainer}
            aria-labelledby="home-heading"
        >
            <div className={styles.homeImageContainer}>
                {homeImage?.url && (
                    <img
                        src={homeImage.url}
                        alt={homeImage.alt ?? 'Home Image'}
                        width={homeImage.width}
                        height={homeImage.height}
                        loading="lazy"
                        style={{ display: 'block', opacity }}
                    />
                )}
            </div>
            <div className={styles.homeTextContainer}>
                <LazyLoadImage
                    id="home-heading"
                    alt="Tignasse logo png"
                    src={LogoImage}
                    style={{
                        transform: `translateX(${firstNameOffset.toString()}px)`,
                    }}
                    className={styles.homeLogoPicture}
                />
                <div className={styles.homeDetailsContainer}>
                    <h2
                        className={styles.homeLastname}
                        style={{
                            transform: `translateX(${lastNameOffset.toString()}px)`,
                        }}
                    >
                        ATELIER DE COIFFURE
                    </h2>
                </div>
            </div>
            <div className={styles.footerContainer}>
                <div className={styles.footerColumn}>
                    <h3>Contact</h3>
                    <p>
                        <a href="tel:+33143717575">01 43 71 75 75</a>
                    </p>
                    <p>
                        <a href="mailto:ateliertignasse@gmail.com">
                            ateliertignasse@gmail.com
                        </a>
                    </p>
                </div>
                <div className={styles.footerColumn}>
                    <h3>Horaires</h3>
                    <p>MARDI: 9h00 - 20h00</p>
                    <p>MERCREDI: 9h00 - 20h00</p>
                    <p>JEUDI: 9h00 - 20h00</p>
                    <p>VENDREDI: 9h00 - 20h00</p>
                    <p>SAMEDI: 9h00 - 18h30</p>
                </div>
                <div className={styles.footerColumn}>
                    <h3>Localisation</h3>
                    <p>
                        <a
                            href="https://www.google.com/maps?ll=48.851714,2.389649&z=16&t=m&hl=en-US&gl=US&mapclient=embed&cid=10220968121032739792"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            237 boulevard Voltaire, 75011 Paris, France
                        </a>
                    </p>
                </div>
            </div>
        </section>
    );
};

const HomeComponent = React.memo(Home);
export default HomeComponent;
