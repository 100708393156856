import { RefObject, useEffect, useState } from 'react';

export const useScrollFadeEffect = (
    fadeDistance: number,
    ref: RefObject<HTMLElement>,
): number => {
    const [opacity, setOpacity] = useState(0.8); // Start with default opacity of 0.8

    useEffect(() => {
        const handleScroll = () => {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                const viewportTop = window.innerHeight - rect.top;

                // Fade the element based on its position relative to the viewport
                if (
                    viewportTop > 0 &&
                    viewportTop < window.innerHeight + fadeDistance
                ) {
                    // Fade effect starts when the element hits the bottom of the viewport
                    const distance = Math.max(
                        viewportTop - window.innerHeight,
                        0,
                    );
                    const newOpacity = Math.max(
                        0.8 - (distance / fadeDistance) * 0.8,
                        0.2,
                    );
                    setOpacity(newOpacity);
                } else if (viewportTop >= window.innerHeight + fadeDistance) {
                    setOpacity(0.2); // Ensure opacity does not go below 0.2
                } else if (rect.bottom > 0) {
                    // Reset opacity to 0.8 when element is moving into view
                    setOpacity(0.9);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [fadeDistance, ref]);

    return opacity;
};
