import React, { Suspense } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
} from 'react-router-dom';
import SecureLoading from './components/shared/secureLoading/secureLoading';
import './i18n/i18n';
import './styles/global.css';
import styles from './styles/app.module.css';

import PopupModal from './components/shared/popupModal/popupModal';
import Navbar from './components/shared/navbar/navbar';
import Home from './components/sections/home/home';
import About from './components/sections/about/about';
import Tarifs from './components/sections/tarifs/tarifs';
import Showcase from './components/sections/showcase/showcase';
import BackToTopButton from './components/shared/backToTopButton/BackToTopButton';
import Footer from './components/shared/footer/footer';
import PrivacyPolicy from './components/sections/privacyPolicyTermsOfService/privacyPolicy';
import TermsOfService from './components/sections/privacyPolicyTermsOfService/termsOfService';

const App: React.FC = () => {
    const location = useLocation();
    const noModalPaths = ['/privacyPolicy', '/termsOfService'];

    return (
        <div className={styles.App}>
            {!noModalPaths.includes(location.pathname) && <PopupModal />}
            <Navbar />
            <main>
                <Suspense fallback={<SecureLoading />}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <>
                                    <Home />
                                    <About />
                                    <Tarifs />
                                    <Showcase />
                                    <BackToTopButton />
                                </>
                            }
                        />
                        <Route
                            path="/privacyPolicy"
                            element={<PrivacyPolicy />}
                        />
                        <Route
                            path="/termsOfService"
                            element={<TermsOfService />}
                        />
                    </Routes>
                </Suspense>
            </main>
            <Footer />
        </div>
    );
};

const AppWrapper: React.FC = () => {
    return (
        <Router>
            <App />
        </Router>
    );
};

export default AppWrapper;
