import { createClient } from '@sanity/client';
import { ISanityEnvironment } from '../interfaces/sanityInterfaces';

const sanityEnvironment: ISanityEnvironment = {
    projectId: import.meta.env.VITE_SANITY_PROJECT_ID,
    dataset: import.meta.env.VITE_SANITY_DATASET,
    apiVersion: import.meta.env.VITE_SANITY_API_VERSION,
};

if (
    !sanityEnvironment.projectId ||
    !sanityEnvironment.dataset ||
    !sanityEnvironment.apiVersion
) {
    throw new Error('Sanity environment variables not provided.');
}

export const client = createClient({
    projectId: sanityEnvironment.projectId,
    dataset: sanityEnvironment.dataset,
    apiVersion: sanityEnvironment.apiVersion,
    useCdn: false,
});
