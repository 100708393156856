import React from 'react';

import App from './app';

export default function Main() {
    return (
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}
