import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './privacyPolicyTermsOfService.module.css';

const TermsOfService: React.FC = React.memo(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/');
    };

    return (
        <>
            <Helmet>
                <title>{t('termsOfService.title')} - Atelier Tignasse</title>
            </Helmet>
            <div className={styles.container}>
                <main className={styles.main}>
                    <h1 className={styles.title}>
                        {t('termsOfService.title')}
                    </h1>
                    <p className={styles.text}>
                        <strong className={styles.subtitle}>
                            {t('termsOfService.termsOfServiceTitle')}
                        </strong>
                        <br />
                        {t('termsOfService.termsOfServiceContent')}
                    </p>
                    <p className={styles.text}>
                        <strong className={styles.subtitle}>
                            {t('termsOfService.useOfContentTitle')}
                        </strong>
                        <br />
                        {t('termsOfService.useOfContentContent')}
                    </p>
                    <p className={styles.text}>
                        <strong className={styles.subtitle}>
                            {t('termsOfService.changesToPolicyTitle')}
                        </strong>
                        <br />
                        {t('termsOfService.changesToPolicyContent')}
                    </p>
                    <button
                        className={styles.returnButton}
                        onClick={handleButtonClick}
                    >
                        {t('returnButton.text')}
                    </button>
                </main>
            </div>
        </>
    );
});

export default TermsOfService;
