import { useEffect, useState } from 'react';

export const useScrollParallax = (speed = 0.1, direction = 1) => {
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        let frameId: number;
        const updateParallax = () => {
            setOffset(window.scrollY * speed * direction);
            frameId = requestAnimationFrame(updateParallax);
        };

        frameId = requestAnimationFrame(updateParallax);

        return () => {
            cancelAnimationFrame(frameId);
        };
    }, [speed, direction]);

    return offset;
};
