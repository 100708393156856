import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './privacyPolicyTermsOfService.module.css';

const PrivacyPolicy: React.FC = React.memo(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/');
    };

    return (
        <>
            <Helmet>
                <title>{t('privacyPolicy.title')} - Atelier Tignasse</title>
            </Helmet>
            <div className={styles.container}>
                <main className={styles.main}>
                    <h1 className={styles.title}>{t('privacyPolicy.title')}</h1>
                    <p className={styles.text}>
                        <strong className={styles.subtitle}>
                            {t('privacyPolicy.informationCollectionTitle')}
                        </strong>
                        <br />
                        {t('privacyPolicy.informationCollectionContent')}
                    </p>
                    <p className={styles.text}>
                        <strong className={styles.subtitle}>
                            {t('privacyPolicy.dataProtectionTitle')}
                        </strong>
                        <br />
                        {t('privacyPolicy.dataProtectionContent')}
                    </p>
                    <button
                        className={styles.returnButton}
                        onClick={handleButtonClick}
                    >
                        {t('returnButton.text')}
                    </button>
                </main>
            </div>
        </>
    );
});

export default PrivacyPolicy;
