import React, { useEffect, useState } from 'react';
import styles from './backToTopButton.module.css';

const BackToTopButton: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const pathLength = 314.159;

        const handleScroll = () => {
            const scrollDistance =
                document.body.scrollHeight - window.innerHeight;
            const scrolled = window.scrollY;
            const scrollProgress = scrolled / scrollDistance;

            setProgress(pathLength * (1 - scrollProgress));
            setIsVisible(scrolled > 500);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div
            className={`${styles.progressWrap} ${isVisible ? styles.activeProgress : ''}`}
            onClick={scrollToTop}
        >
            <svg
                className={styles.progressCircle}
                width="100%"
                height="100%"
                viewBox="-1 -1 102 102"
            >
                <path
                    d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                    style={{
                        strokeDasharray: '314.159 314.159',
                        strokeDashoffset: progress,
                    }}
                />
                <path
                    d="M51 35 L32 60 M51 35 L70 60"
                    stroke="#000"
                    strokeWidth={7}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default BackToTopButton;
