import React from 'react';
import styles from './navLink.module.css';

interface NavLinkProps {
    href: string;
    label: string;
    className?: string;
    onClick?: () => void;
}

const NavLink: React.FC<NavLinkProps> = ({
    href,
    label,
    className,
    onClick,
}) => (
    <a
        href={href}
        className={`${styles.navLink} ${className ?? ''}`}
        onClick={onClick}
    >
        {label}
    </a>
);

export default NavLink;
