import React, { useEffect, useState } from 'react';
import { fetchPopupModal } from '../../../services/sanity/sanityServices';
import { IPopupModal } from '../../../interfaces/sanityInterfaces';
import styles from './popupModal.module.css';

const PopupModal: React.FC = () => {
    const [modalData, setModalData] = useState<IPopupModal | null>(null);

    useEffect(() => {
        fetchPopupModal()
            .then((data) => {
                if (data?.isActive) {
                    setModalData(data);
                }
            })
            .catch((error: unknown) => {
                console.error('Failed to fetch modal data:', error);
            });
    }, []);

    if (!modalData) return null;

    const hasImage = modalData.image?.asset.url ? true : false;

    return (
        <div className={styles.popup}>
            <div
                className={styles.modal}
                style={{ gridTemplateColumns: hasImage ? '1fr 1fr' : '1fr' }}
            >
                {hasImage && (
                    <img
                        src={modalData.image?.asset.url}
                        alt="Popup Modal"
                        className={styles.modalImage}
                    />
                )}
                <div className={styles.textContainer}>
                    <h1 className={styles.title}>{modalData.title}</h1>
                    <p className={styles.description}>
                        {modalData.description}
                    </p>
                </div>
                <button
                    onClick={() => {
                        setModalData(null);
                    }}
                    className={styles.closeButton}
                >
                    &times;
                </button>
            </div>
        </div>
    );
};

export default PopupModal;
