import React from 'react';
import BrandLogo from '../brandLogo/brandLogo';
import HamburgerMenu from '../hamburgerMenu/hamburgerMenu';
import NavLink from '../navLink/navLink';
import { useTranslation } from 'react-i18next';

import styles from './navbar.module.css';

const Navbar: React.FC = React.memo(() => {
    const { t } = useTranslation();

    return (
        <nav className={styles.navbarContainer}>
            <BrandLogo reload />
            <ul className={styles.desktopMenu}>
                <li>
                    <NavLink href="/#about" label={t('navigation.about')} />
                </li>
                <li>
                    <NavLink href="/#tarifs" label={t('navigation.tarifs')} />
                </li>
                <li>
                    <NavLink
                        href="/#showcase"
                        label={t('navigation.showcase')}
                    />
                </li>
            </ul>
            <div className={styles.mobileMenu}>
                <HamburgerMenu />
            </div>
        </nav>
    );
});

export default Navbar;
