import { useEffect, useState } from 'react';

export const useImage = (
    fetchImage: () => Promise<{
        url: string;
        alt?: string;
        width: number;
        height: number;
    }>,
) => {
    const [image, setImage] = useState<{
        url: string;
        alt?: string;
        width: number;
        height: number;
    } | null>(null);

    useEffect(() => {
        fetchImage()
            .then((image) => { setImage(image); })
            .catch((error: unknown) =>
                { console.error('Failed to fetch image', error); },
            );
    }, [fetchImage]);

    return image;
};
