import React, { useCallback } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import styles from './brandLogo.module.css';
import LogoAtelier from '../../../../images/logotignasseatlier.png';

interface BrandLogoProps {
    reload?: boolean;
    highlight?: boolean;
}

const BrandLogo: React.FC<BrandLogoProps> = ({
    reload = false,
    highlight = false,
}) => {
    const reloadPage = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            if (reload) {
                e.preventDefault();
                window.location.href = '/';
            }
        },
        [reload],
    );

    const logoSrc = LogoAtelier;
    const logoClass = styles.logoPictureAtelier;
    const buttonLabel = 'Go to homepage';

    return (
        <div className={styles.brandLogo}>
            <button
                onClick={reload ? reloadPage : undefined}
                aria-label={buttonLabel}
            >
                <LazyLoadImage
                    alt="Tignasse logo"
                    src={logoSrc}
                    className={logoClass}
                />
                {highlight && (
                    <div className={styles.navbarBrandHighlight}></div>
                )}
            </button>
        </div>
    );
};

export default BrandLogo;
