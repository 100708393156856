import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './about.module.css';
import { fetchAboutImages } from '../../../services/sanity/sanityServices';
import KeuneLogo from '../../../../images/keune.png';

interface ImageData {
    url: string;
    description: string;
}

interface AboutImagesData {
    globalDescription: string;
    images: ImageData[];
}

const About: React.FC = React.memo(() => {
    const { t } = useTranslation();
    const aboutRef = useRef<HTMLDivElement>(null);
    const [aboutImages, setAboutImages] = useState<AboutImagesData | null>(
        null,
    );
    useEffect(() => {
        async function getAboutImages() {
            try {
                const data = await fetchAboutImages();
                setAboutImages(data);
            } catch (error) {
                console.error('Failed to fetch about images', error);
            }
        }
        void getAboutImages();
    }, []);

    return (
        <section
            id="about"
            ref={aboutRef}
            className={styles.aboutContainer}
            aria-labelledby="about-heading"
        >
            <div className={styles.leftSide}>
                {aboutImages && (
                    <div
                        className={styles.imageGrid}
                        aria-label={aboutImages.globalDescription}
                    >
                        {aboutImages.images.map((image, index) => (
                            <div key={index} className={styles.imageWrapper}>
                                <img src={image.url} alt={image.description} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={styles.rightSide}>
                <article className={styles.aboutSection}>
                    <h3>{t('about.title')}</h3>
                    <p dangerouslySetInnerHTML={{ __html: t('about.bio') }}></p>
                </article>
                <article className={styles.partnersSection}>
                    <h3>{t('about.partnersTitle')}</h3>
                    <div className={styles.partners}>
                        <img
                            src={KeuneLogo}
                            alt="Keune Logo"
                            className={styles.logo}
                        />
                    </div>
                </article>
            </div>
        </section>
    );
});

export default About;
