import ReactDOM from 'react-dom/client';
import Main from './main';

const rootElement = document.getElementById('root');
if (!rootElement) {
    console.error('Failed to find the root element');
    document.body.innerHTML = '<p>Application cannot be loaded right now.</p>';
} else {
    const root = ReactDOM.createRoot(rootElement);
    root.render(<Main />);
}
