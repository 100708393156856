import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './tarifs.module.css';
import { fetchTarifs } from '../../../services/sanity/sanityServices';
import { TarifsData } from '../../../interfaces/tarifsInterfaces';

const Tarifs: React.FC = React.memo(() => {
    const { t } = useTranslation();
    const tarifsRef = useRef<HTMLDivElement>(null);
    const [tarifsData, setServicesData] = useState<TarifsData | null>(null);

    useEffect(() => {
        async function getServices() {
            const data = await fetchTarifs();
            setServicesData(data);
        }
        void getServices();
    }, []);

    return (
        <div id="tarifs" ref={tarifsRef} className={styles.tarifsContainer}>
            <h1 className={styles.tarifsTitle}>{t('tarifs.tarifsTitle')}</h1>
            <div className={styles.sectionsContainer}>
                {tarifsData?.sections.map((section, sectionIndex) => (
                    <div key={sectionIndex} className={styles.section}>
                        <div className={styles.header}>
                            <img
                                src={section.svgImage.asset.url}
                                className={styles.icon}
                                alt={`${section.title} icon`}
                                style={{
                                    display: section.svgImage.asset.url
                                        ? 'inline'
                                        : 'none',
                                }}
                            />

                            <h2 className={styles.title}>{section.title}</h2>
                        </div>
                        {section.subsections.map(
                            (subsection, subsectionIndex) => (
                                <div
                                    key={subsectionIndex}
                                    className={styles.serviceItem}
                                >
                                    <h3>{subsection.title}</h3>
                                    {subsection.descriptions.map(
                                        (description, descriptionIndex) => (
                                            <div
                                                key={descriptionIndex}
                                                className={
                                                    styles.descriptionBlock
                                                }
                                            >
                                                <p>{description.text}</p>
                                                <p className={styles.prix}>
                                                    {description.prix}
                                                </p>
                                            </div>
                                        ),
                                    )}
                                </div>
                            ),
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
});

export default Tarifs;
