import { IAboutImage, IPopupModal } from '../../interfaces/sanityInterfaces';
import { TarifsData } from '../../interfaces/tarifsInterfaces';
import {
    ImageGallery,
    GalleryImage,
} from '../../interfaces/showcaseInterfaces';
import { IHomeImage } from '../../interfaces/sanityInterfaces';
import { client } from '../../utils/sanityUtils';

export async function fetchAboutImages(): Promise<{
    globalDescription: string;
    images: { url: string; description: string }[];
}> {
    const query = `*[_type == "aboutImage"][0]{
        globalDescription,
        images[]{
            image{
                asset->{
                    _id,
                    url
                }
            },
            description
        }
    }`;

    try {
        const data: IAboutImage | null = await client.fetch(query);
        if (!data) {
            throw new Error('No data found.');
        }

        const images = data.images.map((img) => ({
            url: img.image.asset.url,
            description: img.description,
        }));

        return {
            globalDescription: data.globalDescription,
            images,
        };
    } catch (error) {
        console.error('Failed to fetch about images', error);
        return {
            globalDescription: 'Failed to fetch about images',
            images: [],
        };
    }
}

export async function fetchShowcaseImages(): Promise<GalleryImage[]> {
    const query = `*[_type == "imageGallery"]{
      globalDescription,
      images[]{
        "url": asset->url,
        description // Ensure description is queried as well
      }
    }`;

    try {
        const data: ImageGallery[] = await client.fetch(query);
        if (data.length === 0) {
            return [];
        }
        const globalDescription = data[0].globalDescription;
        const allImages: GalleryImage[] = data.flatMap((item) =>
            item.images.map((image) => ({
                ...image,
                globalDescription,
            })),
        );
        return allImages;
    } catch (error) {
        console.error('Failed to fetch showcase images', error);
        return [];
    }
}

export async function fetchTarifs(): Promise<TarifsData | null> {
    const query = `*[_type == "tarifs"]{
        sections[]{
            title,
            svgImage{
                asset->{
                    url
                }
            },
            subsections[]{
                title,
                descriptions[]{
                    text,
                    prix
                }
            }
        }
    }`;

    try {
        const data: TarifsData[] = await client.fetch(query);
        return data[0];
    } catch (error) {
        console.error('Failed to fetch services', error);
        return null;
    }
}

export async function fetchHomeImage(): Promise<{
    url: string;
    alt: string;
    width: number;
    height: number;
}> {
    const query = `*[_type == "homeImage"][0]{
        image{
            asset->{
                _id,
                url,
                metadata {
                    dimensions {
                        width,
                        height
                    }
                }
            }
        },
        alt
    }`;

    try {
        const data: IHomeImage | null = await client.fetch(query);
        const url = data?.image.asset.url;
        const alt = data?.alt ?? 'Display of Tignasse picture';
        const width = data?.image.asset.metadata.dimensions.width;
        const height = data?.image.asset.metadata.dimensions.height;

        if (!url || !width || !height) {
            throw new Error('Image URL, width, or height not found.');
        }

        return { url, alt, width, height };
    } catch (error) {
        console.error('Failed to fetch home image', error);
        return {
            url: '',
            alt: 'Failed to fetch home image',
            width: 0,
            height: 0,
        };
    }
}

export async function fetchPopupModal(): Promise<IPopupModal | null> {
    const query = `*[_type == "popupModal" && isActive == true][0]{
        isActive,
        image {
            asset->{
                url
            }
        },
        title,
        description
    }`;

    try {
        const data: IPopupModal | null = await client.fetch(query);
        return data;
    } catch (error: unknown) {
        console.error('Failed to fetch modal data:', error as Error);
        return null;
    }
}
