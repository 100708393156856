import BrandLogo from '../brandLogo/brandLogo';
import NavLink from '../navLink/navLink';
import SocialIcon from '../socialIcon/socialIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './footer.module.css';

const Footer: React.FC = React.memo(() => {
    const { t } = useTranslation();

    return (
        <footer className={styles.footerContainer}>
            <div className={styles.topSection}>
                <div className={styles.logoSection}>
                    <BrandLogo reload />
                </div>
                <div className={styles.socialSection}>
                    <ul className={styles.socialIcons}>
                        <SocialIcon
                            href="https://www.instagram.com/tignasseparis/"
                            iconName="Instagram"
                            ariaLabel="Visit Instagram profile"
                            size="large"
                        />
                    </ul>
                </div>
            </div>
            <div className={styles.navSection}>
                <ul className={styles.navLinksLeft}>
                    <li>
                        <NavLink href="/#about" label={t('navigation.about')} />
                    </li>
                    <li>
                        <NavLink
                            href="/#tarifs"
                            label={t('navigation.tarifs')}
                        />
                    </li>
                    <li>
                        <NavLink
                            href="/#showcase"
                            label={t('navigation.showcase')}
                        />
                    </li>
                </ul>
                <ul className={styles.navLinksRight}>
                    <li>
                        <NavLink
                            href="/termsOfService"
                            label={t('termsOfService.title')}
                        />
                    </li>
                    <li>
                        <NavLink
                            href="/privacyPolicy"
                            label={t('privacyPolicy.title')}
                        />
                    </li>
                </ul>
            </div>
            <div className={styles.bottomSection}>
                <p className={styles.copyright}>
                    <small>{t('footer.copyright')}</small>
                </p>
            </div>
        </footer>
    );
});

export default Footer;
