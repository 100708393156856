import React from 'react';
import styles from './secureLoading.module.css';
import BrandLogo from '../brandLogo/brandLogo';

interface SecureLoadingProps {
    message?: string;
}

const SecureLoading: React.FC<SecureLoadingProps> = () => {
    return (
        <div className={styles.loadingContainer}>
            <BrandLogo reload />
            <div className={styles.profileMainLoader}>
                <div className={styles.loader}>
                    <svg
                        className={styles.circularLoader}
                        viewBox="25 25 50 50"
                    >
                        <circle
                            className={styles.loaderPath}
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            stroke="#000"
                            strokeWidth="2"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default SecureLoading;
