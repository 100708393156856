import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import styles from './socialIcon.module.css';

interface SocialIconProps {
    href: string;
    iconName: 'Instagram';
    ariaLabel: string;
    size?: string;
}

const iconMapping = {
    Instagram: faInstagram,
};

const SocialIcon: React.FC<SocialIconProps> = ({
    href,
    iconName,
    ariaLabel,
    size,
}) => (
    <li>
        <a href={href} aria-label={ariaLabel} className={styles.icon}>
            <FontAwesomeIcon
                icon={iconMapping[iconName]}
                className={size ? styles[size] : ''}
            />
        </a>
    </li>
);

export default SocialIcon;
